import React, { useContext, useState } from 'react';
import cx from 'classnames';
import * as paths from 'paths.js';
import { Button, Typography } from '@zydalabs/zac-react';
import { SettingsIcon, PlusIcon } from '@zydalabs/zac-icons-react';
import 'style.css';

import { useSelectedStore, useReplaceParams, useMobile } from 'hooks/index';
import noOrders from 'assets/no-orders.svg';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import BetaAlert from 'common/components/BetaAlert';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import useUpdateStoreDriver from 'service/hooks/verd/useUpdateStoreDriver';
import useDeleteStoreDriver from 'service/hooks/verd/useDeleteStoreDriver';
import { DeleteRider } from 'components/common/verd';
import { More, Modal, Spinner, LoadingContainer, ToggleButton } from 'components/kit';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import MobileList from './MobileList';
import SettingsPage from '../SettingsPage';
import { useFetchVerdDrivers } from '../../../service/hooks';
import { RIDER_AVAILABILITY } from '../enums';
import useRidersHeader from './RidersHeader';

const RidersScreen = () => {
  const { user } = useContext(userContext);
  const selectedStoreId = useSelectedStore();
  const cellClassName = 'px-4 py-3 border-b border-gray-100';
  const { direction, lang, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const replace = useReplaceParams();
  const { data, mutate, isLoading } = useFetchVerdDrivers({ storeId: selectedStoreId });
  const updateStoreDriver = useUpdateStoreDriver();
  const deleteStoreDriver = useDeleteStoreDriver();

  const head = [
    translations.FULL_NAME,
    translations.PHONE_NUMBER,
    translations.JOB_TYPE,
    translations.STATUS,
    translations.ACTIONS,
  ];
  const isMobile = useMobile();
  const [isSomethingLoading, setIsSomethingLoading] = useState();
  const [isBetaAlertVisible, setIsBetaAlertVisible] = useState(true);
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const { filterRidersWithSearch, ridersHeaderComponent } = useRidersHeader(data);

  const handleRiderDeletion = async rider => {
    setIsSomethingLoading(true);
    const response = await deleteStoreDriver({
      id: rider.id,
      userId: user.id,
    });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    }
    mutate().finally(() => {
      setIsSomethingLoading(false);
    });
  };

  const isRidersLoading = isLoading || isSomethingLoading;

  const ZeroResultsComponent = (
    <div className="flex flex-col items-center">
      <img className="mt-4" src={noOrders} alt="" />
      <span className="mb-2 mt-6 text-lg font-semibold px-1">
        <Text value={translations.NO_RESULTS} />
      </span>
      <p className="text-gray-700">
        <Text value={translations.COULDNT_FIND_ORDER} />
      </p>
    </div>
  );

  const ZeroRidersComponent = (
    <div className="flex flex-col items-center justify-center h-full">
      <Link to={paths.createRider}>
        <Button startIcon={<PlusIcon />} text={translate(translations.ADD_NEW_RIDER)} onClick={() => ({})} rounded />
      </Link>
    </div>
  );

  return (
    <Layout
      top={
        <>
          {isBetaAlertVisible && <BetaAlert onClose={() => setIsBetaAlertVisible(false)} />}
          <Breadcrumbs
            links={[paths.verd]}
            path={translations.breadcrumbs.VERD}
            right={
              <div className="flex gap-4 items-center">
                {isMobile && (
                  <Link to={paths.createRider}>
                    <Button
                      startIcon={<PlusIcon />}
                      text={translate(translations.ADD_NEW_RIDER)}
                      onClick={() => ({})}
                      rounded
                    />
                  </Link>
                )}
                <div>
                  <Button
                    variant="secondary"
                    size="small"
                    startIcon={<SettingsIcon width="20px" />}
                    rounded
                    onClick={() => setIsSettingsOpened(true)}
                  />
                </div>
              </div>
            }
          />
        </>
      }
    >
      <div className="h-full" style={{ direction }}>
        <div className="pb-12 h-full">
          {!data ? (
            <Spinner />
          ) : !data.length ? (
            <>{ZeroRidersComponent}</>
          ) : isMobile ? (
            <LoadingContainer isLoading={isRidersLoading}>
              <MobileList handleRiderDeletion={handleRiderDeletion} riders={data} />
            </LoadingContainer>
          ) : (
            <>
              <div className="flex flex-row mb-4 justify-between gap-6">{ridersHeaderComponent}</div>
              {filterRidersWithSearch?.length === 0 ? (
                <>{ZeroResultsComponent}</>
              ) : (
                <LoadingContainer isLoading={isRidersLoading}>
                  <table className="w-full border border-gray-100">
                    <thead>
                      <tr className="font-semibold bg-gray-100">
                        {head.map(item => (
                          <td className="p-4" key={item}>
                            <Text value={item} />
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filterRidersWithSearch?.map(rider => (
                        <tr key={rider.id}>
                          <td className={`${cellClassName}`}>{rider.name}</td>
                          <td className={`${cellClassName}`}>{rider.phone}</td>
                          <td className={`${cellClassName}`}>{rider.jobType}</td>
                          <td className={`${cellClassName}`}>
                            <div className="flex justify-start items-center">
                              <ToggleButton
                                value={rider.onShift}
                                testId_openDropDown="rider-availability"
                                testId_selectedData="rider-availability-name"
                                testId_listData="rider-availability-list"
                                name={`rider-availability ${rider.id}`}
                                onChange={async newAvailability => {
                                  setIsSomethingLoading(true);
                                  await updateStoreDriver({
                                    storeId: selectedStoreId,
                                    id: rider.id,
                                    onShift: newAvailability.target.value === RIDER_AVAILABILITY.AVAILABLE,
                                  });
                                  mutate().finally(() => {
                                    setIsSomethingLoading(false);
                                  });
                                }}
                              />
                              <Typography variant="body14">
                                {rider.onShift ? translate(translations.AVAILABLE) : translate(translations.OFF_DUTY)}
                              </Typography>
                            </div>
                          </td>
                          <td className={cx(`${cellClassName} w-px`)}>
                            <div className="flex items-center">
                              <div className={cx(direction === 'ltr' ? 'ml-2' : 'mr-2')}>
                                <Modal>
                                  {({ open, close }) => (
                                    <More
                                      kind="action"
                                      iconSize="md"
                                      size="smIcon"
                                      className="border-none"
                                      icon="more_horiz"
                                      items={[
                                        {
                                          title: (
                                            <Link
                                              to={replace(paths.editRider, {
                                                omitQuery: true,
                                                params: { riderId: rider.id },
                                              })}
                                            >
                                              <Text value={translations.EDIT} />
                                            </Link>
                                          ),
                                          onClick: () => {
                                            replace(paths.editRider, {
                                              omitQuery: true,
                                              params: { riderId: rider.id },
                                            });
                                          },
                                        },
                                        {
                                          title: <Text value={translations.DELETE} />,
                                          onClick: () => {
                                            open({
                                              title:
                                                rider.assignedOrdersCount > 0
                                                  ? translate(translations.DELETE_RIDER_MODAL_TITLE, lang)
                                                  : rider.name,
                                              body:
                                                rider.assignedOrdersCount > 0 ? (
                                                  <Text
                                                    className="px-4"
                                                    value={translations.DELETE_RIDER_ERROR}
                                                    payload={rider.name}
                                                  />
                                                ) : (
                                                  <DeleteRider
                                                    rider={rider}
                                                    onClose={close}
                                                    handleRiderDeletion={handleRiderDeletion}
                                                  />
                                                ),
                                              size: 'max-w-md',
                                            });
                                          },
                                        },
                                      ]}
                                    />
                                  )}
                                </Modal>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </LoadingContainer>
              )}
            </>
          )}
        </div>
      </div>
      <Slideover
        isOpen={isSettingsOpened}
        setSlideOver={setIsSettingsOpened}
        body={<SettingsPage setIsSettingsOpened={setIsSettingsOpened} />}
      />
    </Layout>
  );
};

export default RidersScreen;
