import React, { useContext, useState, useEffect } from 'react';
import { Typography, Button, TextInput, useTheme } from '@zydalabs/zac-react';
import { ArrowLeftIcon } from '@zydalabs/zac-icons-react';
import { useQuery } from '@apollo/client';
import 'style.css';

import * as schemas from 'components/service/Page/schemas';
import * as translations from 'constants/translations';
import * as userService from 'services/user';
import { context as notificationsContext } from 'context/notifications';
import { useSelectedStore } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useUpdateStoreCourierSettings } from 'service/hooks';
import { RadioList } from 'components/form/elements';
import EnableVerdSetting from '../../../components/common/verd/EnableVerdSetting';

const SettingsPage = ({ setIsSettingsOpened }) => {
  const {
    selectedStore: { restaurantCourierSetting, id },
    setSelectedStore,
    settings: { enableVerd } = {},
  } = useContext(userContext);
  const { translate, lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const selectedStoreId = useSelectedStore();
  const { colors } = useTheme();
  const updateStoreCourierSettings = useUpdateStoreCourierSettings();
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const { driverMaxCapacity, autoAssignMechanism, courierId } = verd || {};

  const [autoAssignRider, setAutoAssignRider] = useState(autoAssignMechanism);
  const [maxCapacity, setMaxCapacity] = useState(driverMaxCapacity);
  const [isLoading, setIsLoading] = useState(false);

  const autoAssignRiderStates = [
    { value: 'round_robin', title: `${translate(translations.ROUND_ROBIN)}` },
    { value: 'auto_assign_if_courier_fail', title: `${translate(translations.AFTER_COURIER_FAILS)}` },
    { value: 'do_not_assign', title: `${translate(translations.DO_NOT_AUTO_ASSIGN)}` },
  ];

  useEffect(() => {
    setMaxCapacity(driverMaxCapacity);
    setAutoAssignRider(autoAssignMechanism);
  }, [driverMaxCapacity, autoAssignMechanism]);

  const { refetch } = useQuery(schemas.USER, {
    variables: {
      storeId: id,
      id: userService.getUserId(),
    },
    onCompleted: data => {
      setSelectedStore(data.restaurant);
      setIsLoading(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSubmit = async () => {
    setIsLoading(true);
    setMaxCapacity(maxCapacity);
    setAutoAssignRider(autoAssignRider);
    const res = await updateStoreCourierSettings({
      autoAssignMechanism: autoAssignRider.toUpperCase(),
      courierId: parseInt(courierId),
      driverMaxCapacity: parseInt(maxCapacity),
      storeId: parseInt(selectedStoreId),
    });
    if (res?.errors?.length) {
      notifications.show(<Typography>{[res?.errors?.[0]?.message]}</Typography>, 'error');
      setIsLoading(false);
    } else {
      await refetch();
      setIsSettingsOpened(false);
    }
  };

  return (
    <div className="mx-6 mt-6 flex flex-col">
      <div className="flex items-center border-b pb-4 justify-between">
        <div className="flex items-center">
          <Button
            variant="ghost"
            startIcon={<ArrowLeftIcon width="20px" color="black" />}
            onClick={() => setIsSettingsOpened(false)}
          />
          <Typography variant="heading20">{translate(translations.SETTINGS)}</Typography>
        </div>
        <Button
          disabled={!enableVerd}
          text={translate(translations.SAVE)}
          isLoading={isLoading}
          rounded
          onClick={() => onSubmit()}
        />
      </div>
      <div style={{ direction }}>
        <div className="mt-7 pb-9 border-b">
          <div className="flex flex-row items-start justify-between">
            <div>
              <Typography variant="heading16">{translate(translations.ENABLE_VERD)}</Typography>
              <Typography variant="body14" color={colors.gray[500]}>
                {translate(translations.ENABLE_VERD_DESC)}
              </Typography>
            </div>
            <EnableVerdSetting />
          </div>
        </div>
        {enableVerd && (
          <>
            <div className="mt-7 pb-9 border-b">
              <div>
                <Typography variant="heading16">{translate(translations.RIDER_CAPACITY)}</Typography>
                <Typography variant="body14" color={colors.gray[500]}>
                  {translate(translations.RIDER_CAPACITY_DESC)}
                </Typography>
                <div className="w-3/5">
                  <div className="text-input mt-6 ">
                    <TextInput
                      isDisabled={isLoading}
                      value={maxCapacity}
                      label={translate(translations.MAX_ORDER_PER_RIDERS)}
                      onChange={e => setMaxCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-7 pb-9">
              <div>
                <Typography variant="heading16">{translate(translations.AUTO_ASSIGN_RIDER)}</Typography>
                <Typography variant="body14" color={colors.gray[500]}>
                  {translate(translations.AUTO_ASSIGN_RIDER_DESC)}
                </Typography>
                <RadioList
                  items={autoAssignRiderStates || []}
                  value={autoAssignRider}
                  onChange={option => setAutoAssignRider(option.target.value)}
                  bodyElementClassName="py-2"
                  elementClassName="py-1"
                  elementStyle={{
                    direction: lang === 'ar' ? 'rtl' : 'unset',
                  }}
                  containerStyle={{
                    marginTop: '20px',
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
