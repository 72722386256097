import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from '@zydalabs/zac-react';

import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as userService from 'services/user';
import * as schemas from 'components/service/Page/schemas';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { enableVerd } from 'rest';
import { ToggleButton } from 'components/kit';

const EnableVerdSetting = () => {
  const { settings, branches, setSettings, selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const branchesIds = branches.map(branch => branch.id);
  const [enableVerdSettings, setEnableVerdSettings] = useState(settings?.enableVerd);
  const [isLoading, setIsLoading] = useState(true);

  const { refetch } = useQuery(schemas.USER, {
    variables: {
      storeId: selectedStore.id,
      id: userService.getUserId(),
    },
    onCompleted: data => {
      setIsLoading(false);
      setSettings(data.settings);
      setEnableVerdSettings(data.settings?.enableVerd);
    },
    fetchPolicy: 'cache-and-network',
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ToggleButton
      name="enableVERD"
      value={enableVerdSettings}
      onChange={async newVerdState => {
        setEnableVerdSettings(newVerdState.target.value);
        enableVerd(selectedStore, branchesIds, newVerdState.target.value)
          .then(() => {
            refetch();
          })
          .catch(error => {
            if (error.response?.data.external_response) {
              const formattedError = JSON.parse(error.response.data.external_response).restaurant_courier_settings?.[0];
              notifications.show(formattedError, 'error');
            } else {
              notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
            }
          });
      }}
    />
  );
};

export default EnableVerdSetting;
