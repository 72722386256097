import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { CheckIcon } from '@zydalabs/zac-icons-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import TextDetails from './TextDetails';

const StoreRider = ({ rider, verd, selected, setSelected }) => {
  const { lang, translate } = useContext(localeContext);
  const { colors } = useTheme();
  const isArabic = lang === 'ar';

  const riderHasFullCapacity = rider.assignedOrdersCount === verd?.driverMaxCapacity;

  const isRiderSelected = selected?.id === rider?.id;

  useEffect(() => {
    if (selected?.id === rider?.id) setSelected(rider);
  }, [rider]);

  return (
    <div
      className={cx(
        'w-100 flex justify-between border rounded-lg mb-2 py-3 px-4 cursor-pointer',
        isRiderSelected && 'border-blue-500 bg-blue-100',
        riderHasFullCapacity ? 'bg-gray-100 cursor-default' : 'cursor-pointer',
      )}
      onClick={() => {
        if (!riderHasFullCapacity) {
          setSelected(rider);
        }
      }}
      aria-hidden="true"
    >
      <div className="flex flex-col">
        <Typography variant="heading14" cursor={!riderHasFullCapacity && 'pointer'}>
          {rider.name}
        </Typography>
        <div>
          <div className={cx('text-xs inline-block w-auto', isArabic ? 'ml-1' : 'mr-1')}>
            <Typography variant="body12" color={riderHasFullCapacity ? colors.red[600] : colors.success.primary}>
              {translate(riderHasFullCapacity ? translations.MAX_CAPACITY : translations.AVAILABLE)}
            </Typography>
          </div>
          {rider.onShift && rider.assignedOrdersCount > 0 && (
            <div className="text-xs inline-block w-auto">
              <TextDetails>
                {translate(translations.UNDERSCORE)}
                {translate(translations.ASSIGNED_ORDERS(rider.assignedOrdersCount))}
              </TextDetails>
            </div>
          )}
        </div>
      </div>
      {isRiderSelected && <CheckIcon width="25px" color={colors.blue.primary} />}
    </div>
  );
};

StoreRider.propTypes = {
  rider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    assignedOrdersCount: PropTypes.number,
    onShift: PropTypes.bool,
  }),
  verd: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
  }),
};

export default StoreRider;
