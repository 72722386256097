import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Button, Typography, useTheme, TextInput } from '@zydalabs/zac-react';
import { SettingsIcon, LogosWhatsAppIcon, ChartColumnIcon } from '@zydalabs/zac-icons-react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import cx from 'classnames';
import 'style.css';

import { Field } from 'components/service';
import * as paths from 'paths.js';
import { PhoneInput } from 'components/form/elements';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { replaceParams } from 'utils/index';
import { useSelectedStore } from 'hooks';
import { RIDER_JOB_TYPES } from 'constants/riderJobTypes';
import { RIDER_LANGUAGES } from 'constants/riderLanguages';
import SearchInput from 'common/components/SearchInput';
import riderValidationSchema from 'components/common/verd/RiderForm/validationSchema';
import StoreRider from './StoreRider';
import StoreCourier from './StoreCourier';
import useOnSubmitRider from './useOnSubmitRider';

const AssignDeliveryModal = ({
  mutateFetchVerdDrivers,
  close,
  handleAssignDriverToOrder,
  handleAssignCourierToOrder,
  openOrderDeliveryConfirmationPopUp,
  isCourierNotDeclinedTheRequest,
  assigned,
  restaurantCouriersWithLogos,
  riders,
  quickAssignCourierId,
  sendChangeStatus,
  closeable,
  nextOrderStatus,
}) => {
  const { translate } = useContext(localeContext);
  const { settings, selectedStore, branches } = useContext(userContext);
  const { colors } = useTheme();
  const storeId = useSelectedStore();
  const [searchInput, setSearchInput] = useState('');
  const [selected, setSelected] = useState(assigned);

  const { restaurantCourierSetting } = selectedStore || {};
  const branchesIdList = branches.map(branch => branch.id);
  const changeStatus = () => !!sendChangeStatus && sendChangeStatus({ status: nextOrderStatus, sendCourierId: false });
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isVerdRiderSelected = !!selected?.phone;
  const isVerdEnabled = settings?.enableVerd;

  useEffect(() => {
    closeable(false);
  }, []);

  const closeModal = () => {
    close();
    closeable(true);
    changeStatus();
  };

  const routeToVerdPage = () => {
    changeStatus();

    navigate(
      replaceParams(paths.verd, {
        storeId,
      }),
    );
  };

  const filteredCouriersAndRiders = useMemo(() => {
    const restaurantCouriersAndRiders = restaurantCouriersWithLogos?.concat(riders);
    const filterRestaurantCouriersAndRiders = restaurantCouriersAndRiders?.filter(courierOrRider => {
      if (searchInput === '') {
        return courierOrRider;
      }
      return courierOrRider.phone
        ? courierOrRider.name?.toLowerCase()?.includes(searchInput)
        : courierOrRider.courierDetails?.name?.toLowerCase()?.includes(searchInput);
    });

    return filterRestaurantCouriersAndRiders;
  }, [searchInput, selected, setSelected]);

  const ridersList = filteredCouriersAndRiders
    ?.filter(rider => !!rider.phone)
    ?.map(rider => (
      <StoreRider key={rider.id} rider={rider} verd={verd} selected={selected} setSelected={setSelected} />
    ));

  const couriersList = filteredCouriersAndRiders
    ?.filter(rider => !rider.phone && !rider?.inNetwork)
    ?.map(courier => (
      <StoreCourier
        key={Number(courier.courierId)}
        courier={courier}
        selected={selected}
        setSelected={setSelected}
        quickAssignCourierId={quickAssignCourierId}
      />
    ));

  const verdNetworkList = filteredCouriersAndRiders
    ?.filter(rider => !rider.phone && !!rider?.inNetwork)
    ?.map(courier => (
      <StoreCourier
        key={Number(courier.courierId)}
        courier={courier}
        selected={selected}
        setSelected={setSelected}
        quickAssignCourierId={quickAssignCourierId}
      />
    ));

  const assignDelivery = () =>
    isVerdRiderSelected ? handleAssignDriverToOrder(selected) : handleAssignCourierToOrder(selected);

  const handleAssignButton = () =>
    isCourierNotDeclinedTheRequest
      ? openOrderDeliveryConfirmationPopUp({
          assignDriverToOrder: isVerdRiderSelected ? handleAssignDriverToOrder : handleAssignCourierToOrder,
          assignee: selected,
        })
      : assignDelivery();

  const handleSearch = e => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());
  };

  const [isAddRiderLoading, setIsAddingRiderLoading] = useState(false);
  const isAddingRider = selected === 'add_rider';

  const { onSubmit } = useOnSubmitRider({
    mutateFetchVerdDrivers,
    handleAssignDriverToOrder,
    changeStatus: () => sendChangeStatus({ status: nextOrderStatus, sendCourierId: false }),
    hasDontShowAgainButton: !!sendChangeStatus,
    close: () => closeModal(),
    setIsAddingRiderLoading,
    onSubmitMessage: <Typography>{translate(translations.ADD_AND_ASSIGN_RIDER_SUCCESS)}</Typography>,
  });

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        jobType: RIDER_JOB_TYPES.FULL_TIME,
        language: RIDER_LANGUAGES.ENGLISH,
        branches: branchesIdList,
      }}
      validationSchema={isAddingRider && riderValidationSchema}
      onSubmit={onSubmit}
    >
      <Form className="h-full">
        <div className="min-h-full flex flex-col">
          <div className="sticky top-0 bg-white w-full flex justify-between items-center border-b px-6 pt-7 pb-6">
            <Typography variant="heading24">{translate(translations.ASSIGN_DELIVERY)}</Typography>
            <Button
              variant="tertiary"
              text={translate(translations.MANAGE)}
              rounded
              size="small"
              onClick={routeToVerdPage}
              startIcon={<SettingsIcon width="20px" color={colors.blue.primary} />}
            />
          </div>
          <div className="flex flex-col px-6 py-5">
            <SearchInput
              searchValue={searchInput}
              setSearchValue={handleSearch}
              bgColor="bg-gray-100"
              placeholder={translate(translations.SEARCH_PLACEHOLDER)}
            />
            {verdNetworkList.length !== 0 && (
              <>
                <Typography variant="heading14" mt={16} mb={8}>
                  {translate(translations.ACTIVE_VERD_NETWORK(verdNetworkList.length))}
                </Typography>
                {verdNetworkList}
              </>
            )}
            {couriersList.length !== 0 && (
              <>
                <Typography variant="heading14" mt={16} mb={8}>
                  {translate(translations.ACTIVE_COURIERS(couriersList.length))}
                </Typography>
                {couriersList}
              </>
            )}
            {isVerdEnabled && (
              <>
                <Typography variant="heading14" mt={16} mb={8}>
                  {translate(translations.ACTIVE_RIDERS(ridersList.length))}
                </Typography>
                {ridersList}
                <div
                  className={cx(
                    'flex flex-col gap-2 items-baseline w-100 justify-between border rounded-lg mb-2 py-3 px-4',
                    isAddingRider && 'border-blue-500 bg-blue-100',
                  )}
                >
                  <Typography variant="heading14" mb={4}>
                    {translate(translations.ADD_NEW_RIDER)}
                  </Typography>
                  <div>
                    <div className="flex gap-2.5 items-start mb-2">
                      <LogosWhatsAppIcon width="20px" color="black" />
                      <Typography variant="element14">{translate(translations.SEND_ORDER_THROUGH_WHATSAPP)}</Typography>
                    </div>
                    <div className="flex gap-2.5 items-start mb-5">
                      <ChartColumnIcon width="20px" color="black" />
                      <Typography variant="element14">{translate(translations.REALTIME_UPDATES)}</Typography>
                    </div>
                  </div>
                  <div className="flex gap-4 w-full">
                    <div className="text-input" onClick={() => setSelected('add_rider')} aria-hidden="true">
                      <Field
                        type="text"
                        name="name"
                        component={TextInput}
                        placeholder={translate(translations.RIDER_NAME)}
                        size="small"
                      />
                    </div>
                    <div className="text-input h-full" onClick={() => setSelected('add_rider')} aria-hidden="true">
                      <Field
                        type="text"
                        name="phone"
                        component={PhoneInput}
                        defaultCountry={selectedStore.countryCode?.toLowerCase()}
                        size="large"
                        height="h-12"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="sticky bottom-0 bg-white flex justify-between py-4 px-6 border-t gap-4 mt-auto">
            <Button
              variant="secondary"
              text={translate(translations.ASSIGN_LATER)}
              rounded
              isFluid
              size="large"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              type={isAddingRider && 'submit'}
              isLoading={isAddRiderLoading}
              text={isAddingRider ? translate(translations.ADD_AND_ASSIGN_NOW) : translate(translations.ASSIGN_NOW)}
              rounded
              isFluid
              isDisabled={!selected}
              size="large"
              onClick={() => {
                if (!isAddingRider) {
                  handleAssignButton();
                  closeModal();
                }
              }}
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default AssignDeliveryModal;

AssignDeliveryModal.propTypes = {
  close: PropTypes.func,
  closeable: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
  handleAssignCourierToOrder: PropTypes.func,
  openOrderDeliveryConfirmationPopUp: PropTypes.func,
  sendChangeStatus: PropTypes.func,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  quickAssignCourierId: PropTypes.number,
};
