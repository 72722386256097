import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Typography } from '@zydalabs/zac-react';

const OrderDetailsCard = ({
  quantity,
  itemQuantity,
  optionName,
  price,
  currency,
  numberOfEach,
  lang,
  direction,
  isThermalInvoice = false,
  isGift = false,
  shouldHidePricing = false,
}) => (
  <div className="flex justify-between mb-1" style={{ direction }}>
    <div className="flex flex-col">
      <div className="flex">
        <span
          className={cx(
            'text-xs flex',
            !isThermalInvoice && 'font-medium',
            isThermalInvoice ? (lang === 'ar' ? 'ml-2' : 'mr-2') : lang === 'ar' ? 'ml-6' : 'mr-6',
          )}
        >
          <Typography variant="element12" color="GrayText">
            {quantity} X
          </Typography>
        </span>
        <div className="flex flex-col text-xs">
          <Typography variant="element12" color="GrayText">
            <Text selectedLang={lang} value={optionName} className={cx('inline', !isThermalInvoice && 'font-medium')} />
          </Typography>
          {itemQuantity > 1 && (
            <Typography variant="element12" color="GrayText">
              ({numberOfEach}) <Text selectedLang={lang} value={translations.FOR_EACH} className="inline" />
            </Typography>
          )}
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-end items-end">
      {!isGift && price && !shouldHidePricing ? (
        <>
          <span className="text-xs">
            <Text selectedLang={lang} value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {price.toFixed(currency.decimals) * quantity}
          </span>
          {quantity > 1 && (
            <span className="text-xs">
              (
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')} style={{ direction }}>
                {quantity} x
              </span>
              <Text selectedLang={lang} value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {price.toFixed(currency.decimals)})
            </span>
          )}
        </>
      ) : (
        <span>--</span>
      )}
    </div>
  </div>
);
OrderDetailsCard.propTypes = {
  quantity: PropTypes.number.isRequired,
  itemQuantity: PropTypes.number.isRequired,
  optionName: PropTypes.shape({
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }).isRequired,
  numberOfEach: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  direction: PropTypes.string.isRequired,
  isGift: PropTypes.bool,
  isThermalInvoice: PropTypes.bool,
  shouldHidePricing: PropTypes.bool,
};

export default OrderDetailsCard;
